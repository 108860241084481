// src/components/ui/modal/PlaceAddModal.js
import React, { useState } from 'react';
import { db } from '../../../Firebase';
import { collection, addDoc } from 'firebase/firestore';

const PlaceAddModal = ({ isOpen, onClose }) => {
  const [newData, setNewData] = useState({ place: '', address: '', unit: '' });

  const addNewData = async () => {
    if (!newData.place || !newData.address || !newData.unit) {
      alert("모든 필드를 채워주세요.");
      return;
    }
    await addDoc(collection(db, "customerData"), newData);
    onClose(); // 모달 닫기
    setNewData({ place: '', address: '', unit: '' }); // 입력 상태 초기화
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center">
      <div className="relative bg-white rounded-lg shadow-xl p-6 w-full max-w-md m-4">
        <h3 className="text-lg font-medium leading-6 text-gray-900">새 현장 데이터 추가</h3>
        <div className="mt-2">
          <input
            className="w-full p-3 border border-gray-300 rounded mt-2"
            value={newData.place}
            onChange={(e) => setNewData({ ...newData, place: e.target.value })}
            placeholder="현장명"
          />
          <input
            className="w-full p-3 border border-gray-300 rounded mt-2"
            value={newData.address}
            onChange={(e) => setNewData({ ...newData, address: e.target.value })}
            placeholder="주소"
          />
          <input
            className="w-full p-3 border border-gray-300 rounded mt-2"
            value={newData.unit}
            onChange={(e) => setNewData({ ...newData, unit: e.target.value })}
            placeholder="호기"
          />
        </div>
        <div className="mb-2">여러 개의 호기를 입력할 때는 쉼표(,)를 사용하세요.</div>
        <div className="mb-2">데이터를 동시에 입력 할 수 있습니다.</div>
        <div className="mb-2">(예) 101-1, 101-2, 102-1, 102-2</div>
        <div className="mt-4 flex justify-end">
          <button
            onClick={onClose}
            className="ml-2 inline-flex justify-center py-2 px-4 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            취소
          </button>
          <button
            onClick={addNewData}
            className="ml-2 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            추가
          </button>
        </div>
      </div>
    </div>
  );
};

export default PlaceAddModal;
