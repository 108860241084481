import React, { useState, useEffect } from 'react';
import { collection, query, orderBy, limit, getDocs, startAfter, where } from 'firebase/firestore';
import { db } from '../Firebase'; // Firebase 설정 임포트
import { useNavigate } from 'react-router-dom'; // 페이지 이동을 위한 hook
import WorkLogCard from '../components/workLogs/WorkLogCard'; // 작업일지 카드 컴포넌트
import LoadingIndicator from '../components/ui/LoadingIndicator'; // 로딩 인디케이터 UI 컴포넌트
import LoadMoreButton from '../components/workLogs/LoadMoreButton'; // '더보기' 버튼 컴포넌트
import Search from '../components/workLogs/Search'; // 검색 컴포넌트

const WorkLogPage = () => {
  const [workLogs, setWorkLogs] = useState([]); // 전체 작업일지 데이터
  const [displayedLogs, setDisplayedLogs] = useState([]); // 화면에 표시되는 작업일지 데이터
  const [lastDoc, setLastDoc] = useState(null); // 현재 로드된 마지막 문서
  const [isLoading, setIsLoading] = useState(true); // 로딩 상태
  const [isMoreLoading, setIsMoreLoading] = useState(false); // 추가 데이터 로딩 상태
  const [selectedCategory, setSelectedCategory] = useState('');

  const navigate = useNavigate();

  const categories = [
    { label: '🔍점검', value: 'inspection', color: 'bg-green-500' },
    { label: '💥고장', value: 'change', color: 'bg-yellow-500' },
    { label: '🚨승객갇힘', value: 'emergency', color: 'bg-red-500' },
    { label: '🛠수리공사', value: 'repair', color: 'bg-blue-500' }
  ];

  // 컴포넌트 마운트 시 초기 데이터 로드
  useEffect(() => {
    fetchInitialWorkLogs();
  }, []);

  useEffect(() => {
    // 컴포넌트가 마운트될 때 스크롤을 최상단으로 이동
    window.scrollTo(0, 0);
  }, []);

  // 초기 작업일지 데이터 로드 함수
  async function fetchInitialWorkLogs() {
    setIsLoading(true);
    try {
      const q = query(collection(db, "newUnifiedData"), orderBy("createdAt", "desc"), limit(10));
      const documentSnapshots = await getDocs(q);
      const logs = documentSnapshots.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt.toDate(),
      }));
      setWorkLogs(logs);
      setDisplayedLogs(logs);
      setLastDoc(documentSnapshots.docs[documentSnapshots.docs.length - 1]);
    } catch (error) {
      console.error("Error fetching initial work logs: ", error);
    } finally {
      setIsLoading(false);
    }
  }

  // '더보기' 버튼 클릭 시 추가 데이터 로드 함수
  const handleLoadMore = async () => {
    if (isMoreLoading || !lastDoc) return;
    setIsMoreLoading(true);

    try {
      const q = query(collection(db, "newUnifiedData"), orderBy("createdAt", "desc"), startAfter(lastDoc), limit(10));
      const documentSnapshots = await getDocs(q);
      const newLogs = documentSnapshots.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt.toDate(),
      }));
      
      setWorkLogs(workLogs.concat(newLogs));
      setDisplayedLogs(displayedLogs.concat(newLogs));
      setLastDoc(documentSnapshots.docs[documentSnapshots.docs.length - 1]);
    } catch (error) {
      console.error("Error loading more work logs: ", error);
    } finally {
      setIsMoreLoading(false);
    }
  };

  // WorkLogPage 컴포넌트 내부
  const handleSearch = async (searchTerm, searchType = 'text') => {
    setIsLoading(true);
    let queries;

    if (searchType === 'category') {
      // 카테고리 검색
      queries = [
        query(
          collection(db, "newUnifiedData"),
          where("category", "==", searchTerm),
          orderBy("createdAt", "desc"),
          limit(10)
        )
      ];
    } else {
      // 일반 텍스트 검색
      let searchFields = ['place', 'unit', 'creatorName'];
      queries = searchFields.map(field =>
        query(
          collection(db, "newUnifiedData"),
          where(field, ">=", searchTerm),
          where(field, "<=", searchTerm + '\uf8ff'),
          orderBy(field),
          limit(10)
        )
      );
    }

    try {
      let results = await Promise.all(queries.map(q => getDocs(q)));
      let combinedResults = [];
      results.forEach(result => {
        result.docs.forEach(doc => {
          let data = {
            id: doc.id,
            ...doc.data(),
            createdAt: doc.data().createdAt.toDate(),
          };
          // 중복 제거 로직
          if (!combinedResults.some(log => log.id === data.id)) {
            combinedResults.push(data);
          }
        });
      });
      setDisplayedLogs(combinedResults);
    } catch (error) {
      console.error("Error searching work logs: ", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCategorySearch = (categoryValue) => {
    setSelectedCategory(categoryValue);
    handleSearch(categoryValue, 'category'); // 카테고리 검색을 지정
  };

  
  return (
    <div className="p-4 py-8">
      <h1 className="text-3xl font-bold mb-4 p-3 rounded text-center shadow-md cursor-pointer" onClick={() => window.location.reload()}>
        작업일지 📄
      </h1>
      <div className="flex flex-wrap justify-center gap-2 mb-4">
        {categories.map((category) => (
          <button key={category.value} onClick={() => handleCategorySearch(category.value)} className={`px-3 py-2 text-lg font-semibold rounded-md transition-colors duration-300
            ${selectedCategory === category.value ? `${category.color} text-white` : 'bg-white text-gray-600 border border-gray-300 hover:bg-gray-100'}
            flex-grow`}>
            {category.label}
          </button>
        ))}
      </div>
      <Search onSearch={handleSearch} />
      {isLoading ? <LoadingIndicator /> : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
          {displayedLogs.map(log => (
            <WorkLogCard key={log.id} log={log} onClick={() => navigate(`/work-logs/${log.id}`)} />
          ))}
        </div>
      )}
      {lastDoc && !isMoreLoading && <LoadMoreButton isLoading={isMoreLoading} onLoadMore={handleLoadMore} />}
    </div>
  );  
};

export default WorkLogPage;
