import React, { useState, useEffect } from 'react';
import { db } from '../../Firebase';
import { doc, collection, getDocs, query, orderBy, updateDoc, deleteDoc } from 'firebase/firestore';
import PlaceUpdateModal from '../ui/modal/PlaceUpdateModal';
import PlaceAddModal from '../ui/modal/PlaceAddModal';
import PlaceDelModal from '../ui/modal/PlaceDelModal';
import PlaceForm from './PlaceForm';
import PlaceSearch from './PlaceSearch';

const PlaceManager = () => {
  const [customerData, setCustomerData] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [currentEdit, setCurrentEdit] = useState(null); // 현재 수정할 데이터 상태
  const [showDelModal, setShowDelModal] = useState(false);
  const [dataToDelete, setDataToDelete] = useState(null); // 삭제할 데이터 상태
  const [searchTerm, setSearchTerm] = useState('');

  // Firestore에서 데이터를 불러오는 함수
  useEffect(() => {
    const fetchCustomerData = async () => {
      const q = query(collection(db, "customerData"), orderBy("place"));
      const querySnapshot = await getDocs(q);
      setCustomerData(querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    };

    fetchCustomerData();
  }, []);

  // 데이터 수정 모달을 여는 함수
  const openUpdateModal = (data) => {
    setCurrentEdit(data);
    setIsUpdateModalOpen(true);
  };

  // 데이터 수정을 저장하는 함수
  const saveEdit = async (updatedData) => {
    if (!currentEdit) return;
    await updateDoc(doc(db, "customerData", currentEdit.id), updatedData);
    setIsUpdateModalOpen(false); // 모달 닫기
    setCustomerData(customerData.map(data => data.id === currentEdit.id ? { ...data, ...updatedData } : data)); // 로컬 상태 업데이트
  };

  // 데이터 삭제 모달을 여는 함수
  const openDeleteModal = (data) => {
    setDataToDelete(data);
    setShowDelModal(true);
  };

  // 데이터 삭제를 확정하는 함수
  const handleConfirmDelete = async (id) => {
    await deleteDoc(doc(db, "customerData", id));
    setCustomerData(customerData.filter(data => data.id !== id)); // 로컬 상태 업데이트
    setShowDelModal(false); // 모달 닫기
  };

  const handleSearch = (searchValue) => {
    setSearchTerm(searchValue.toLowerCase());
  };

  return (
    <div className="p-4 space-y-4">
      <PlaceSearch onSearch={handleSearch} />
      <div className="flex justify-end items-center mb-4">
        <div></div> {/* 이 div는 레이아웃을 위한 빈 공간으로 사용됩니다. */}
        <button
          onClick={() => setShowAddModal(true)}
          className="text-black font-bold py-2 px-4 rounded hover:bg-gray-100"
        >
          ➕ 현장 추가 🏢
        </button>
      </div>
      {/* 데이터 목록을 표시하는 부분 */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {customerData
          .filter(data => data.place.toLowerCase().includes(searchTerm))
          .map((data) => (
            <PlaceForm
              key={data.id}
              data={data}
              onDelete={openDeleteModal}
              onEdit={openUpdateModal}
            />
        ))}
      </div>
      {/* 모달 컴포넌트들 */}
      {isUpdateModalOpen && currentEdit && (
        <PlaceUpdateModal
          isOpen={isUpdateModalOpen}
          onClose={() => setIsUpdateModalOpen(false)} // 이 부분이 수정되었습니다.
          onSave={saveEdit}
          initialData={currentEdit}
        />
      )}
      <PlaceAddModal
        isOpen={showAddModal}
        onClose={() => setShowAddModal(false)}
      />
      <PlaceDelModal
        isOpen={showDelModal}
        onClose={() => setShowDelModal(false)}
        onConfirmDelete={handleConfirmDelete}
        dataToDelete={dataToDelete}
      />
    </div>
  );
};

export default PlaceManager;
