import React, { useState, useEffect } from 'react';
import RecordCategory from '../ui/recordForm/RecordCategory';
import RecordPlace from '../ui/recordForm/RecordPlace';
import DateInput from '../ui/recordForm/RecordDate';
import RecordUnit from '../ui/recordForm/RecordUnit';
import RecordArrivalTime from '../ui/recordForm/RecordArrivalTime';
import RecordCompletionTime from '../ui/recordForm/RecordCompletionTime';
import RecordActionText from '../ui/recordForm/RecordActionText';
import RecordMeasures from '../ui/recordForm/RecordMeasures';
import RecordAction from '../ui/recordForm/RecordAction';
import RecordSpecialNotes from '../ui/recordForm/RecordSpecialNotes';
import ImageUpload from '../ui/recordForm/RecordImage';
import RecordWorker from '../ui/recordForm/RecordWorker';
import ImageModal from '../ui/modal/ImageModal';
import RecordInven from '../ui/recordForm/RecordInven';

const RecordWorkLog = ({ onSubmit, initialFormData }) => {
  const [showModal, setShowModal] = useState(false);
  const [currentImage, setCurrentImage] = useState('');
  // 상태 관리: 폼 데이터를 관리합니다.
  const [formData, setFormData] = useState({
    category: '', // 카테고리
    date: '', // 날짜
    place: '', // 현장명
    unit: '', // 호기
    workers: [], //작업자 추가
    arrivalTime: '', // 도착시간
    completionTime: '', // 완료시간
    urgentContent: '', // 작업내용
    parts: [], // 사용 부품과 수량을 저장할 새로운 상태
    measures: '', // 조치사항
    resultOfAction: '', // 조치결과
    specialNotes: '', // 세부내역
    images: [],
    ...initialFormData // initialFormData가 있으면 여기서 초기값을 덮어씁니다.
  });

  useEffect(() => {
    if (initialFormData) {
      setFormData(initialFormData);
    }
  }, [initialFormData]);

  // 입력 변경 이벤트 처리: 모든 입력 필드에 대해 공통으로 사용됩니다.
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  };

  // 이미지 클릭 핸들러
  const handleImageClick = (imageUrl) => {
    setCurrentImage(imageUrl);
    setShowModal(true);
  };

  const handleWorkersSelect = (selectedWorkerNames) => {
    // 선택된 작업자의 이름 목록으로 formData 업데이트
    setFormData({ ...formData, workers: selectedWorkerNames });
  };

  const handlePartsSelect = (selectedParts) => {
    // selectedParts는 선택된 부품들의 배열입니다.

    const updatedParts = formData.parts.map(part => {
      // 이미 formData.parts에 존재하는 부품을 찾습니다.
      const existingPart = selectedParts.find(selectedPart => selectedPart.name === part.name);
      if (existingPart) {
        // 존재하는 부품의 수량을 업데이트합니다.
        return { ...part, quantity: existingPart.quantity };
      }
      return part;
    });

    // formData.parts에 존재하지 않는 새 부품을 추가합니다.
    selectedParts.forEach(selectedPart => {
      if (!updatedParts.some(part => part.name === selectedPart.name)) {
        updatedParts.push(selectedPart);
      }
    });

    setFormData({ ...formData, parts: updatedParts });
  };

  // 폼 제출 이벤트 처리: Firestore에 데이터를 추가합니다.
  const handleSubmit = async (e) => {
    e.preventDefault();

    // 필수 필드 검증
    if (!formData.category || !formData.date || !formData.place) {
      alert('카테고리, 날짜, 현장명은 반드시 입력해야 합니다.');
      return;
    }

    console.log('Submitting form data:', formData);

    // 상위 컴포넌트로 폼 데이터 전달
    onSubmit(formData);
  };

  return (
    <div className="max-w-4xl mx-auto p-4">
      <form onSubmit={handleSubmit} className="space-y-4">
        {/* 각 필드에 대한 입력 컴포넌트를 렌더링합니다. */}
        <RecordCategory category={formData.category} onCategoryChange={handleInputChange} />
        <DateInput date={formData.date} onDateChange={handleInputChange} />
        <RecordPlace onPlaceSelect={(selectedPlace) => setFormData({ ...formData, place: selectedPlace })} selectedPlace={formData.place} />
        <RecordUnit selectedPlace={formData.place} selectedUnit={formData.unit} onUnitSelect={(selectedUnit) => setFormData({ ...formData, unit: selectedUnit })} />
        <RecordWorker onWorkersSelect={handleWorkersSelect} selectedWorkers={formData.workers} />
        <div className="flex flex-wrap -mx-2">
          <RecordArrivalTime arrivalTime={formData.arrivalTime} onTimeChange={handleInputChange} />
          <RecordCompletionTime completionTime={formData.completionTime} onTimeChange={handleInputChange} />
        </div>
        <RecordActionText urgentContent={formData.urgentContent} onTextChange={handleInputChange} />
        <RecordInven onPartsSelect={handlePartsSelect} />
        <RecordMeasures measures={formData.measures} onMeasuresChange={handleInputChange} />
        <RecordAction resultOfAction={formData.resultOfAction} onActionChange={(e) => setFormData({ ...formData, resultOfAction: e.target.value })} />
        <RecordSpecialNotes specialNotes={formData.specialNotes} onNotesChange={handleInputChange} />
        <ImageUpload
          initialImages={initialFormData ? initialFormData.images : []}
          onUpload={(newImages) => setFormData({ ...formData, images: newImages })}
          onImageClick={handleImageClick} // 이미지 클릭 핸들러를 prop으로 전달
        />
        {showModal && (
          <ImageModal
            imageUrl={currentImage}
            onClose={() => setShowModal(false)}
          />
        )}
        <div className="flex justify-center mt-4"> {/* mt-4 클래스로 위쪽 여백 추가 */}
          <button type="submit" className="border-2 border-black text-black bg-white hover:bg-gray-100 font-bold py-2 px-4 rounded transition duration-150 ease-in-out">
            저장하기
          </button>
        </div>
      </form>
    </div>
  );
};

export default RecordWorkLog;
