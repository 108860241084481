import React, { useState } from 'react';

const Search = ({ onSearch }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearch = () => {
    if (searchTerm.trim() === '') {
      onSearch(); // 전체 데이터 로드
    } else {
      onSearch(searchTerm, true); // true는 부분 문자열 검색을 나타냄
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <div className="flex justify-center mb-4">
      <input
        type="text"
        placeholder="현장명, 호기, 작업자 검색 가능"
        value={searchTerm}
        onChange={handleInputChange}
        onKeyPress={handleKeyPress}
        className="input border-2 border-gray-200 bg-white h-10 px-5 rounded-lg text-sm focus:outline-none w-full max-w-lg"
      />
      <button onClick={handleSearch} className="ml-4 border-2 border-gray-200 bg-white hover:bg-gray-100 text-black font-bold py-2 px-4 rounded h-10">
        🔍
      </button>
    </div>
  );
};

export default Search;
