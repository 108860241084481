import React, { useState } from 'react';
import controllerImg from '../../assets/controller.gif';
import useAuth from '../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import PersonWorkData from '../dashboard/PersonWorkData';

const ControllerButton = () => {
  const user = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const toggleController = () => {
    setIsOpen(!isOpen);
  };

  const goToWorkLogPage = () => {
    navigate('/record-work-log');
    setIsOpen(false);
  };

  const goToWorkLogs = () => {
    navigate('/work-logs');
    setIsOpen(false);
  };

  // 추가된 함수
  const goToDashboard = () => {
    navigate('/dashboard');
    setIsOpen(false);
  };

  if (!user) {
    return null;
  }

  const expandedClass = "fixed bottom-4 right-4 z-50";
  const contentClass = isOpen ? "scale-95" : "scale-0";
  const baseContentClass = "w-96 h-56 bg-white border-2 border-gray-200 rounded-lg p-4 shadow-lg absolute bottom-0 right-0 origin-bottom-right"; 

  return (
  <div className={expandedClass}>
    <div className={`${baseContentClass} transition-transform duration-500 ease-out transform ${contentClass}`}>
      <p className="mb-4">🕹 {user.name} 님, 항상 안전이 우선입니다.</p>
      {/* Flex 컨테이너 추가 및 flex-wrap, justify-center 클래스를 사용하여 버튼들을 감쌉니다. */}
      <div className="flex flex-wrap justify-center items-center space-x-2">
        <div>
          <button onClick={goToWorkLogs} className="py-2 px-4 border border-black bg-white text-black font-bold rounded text-base">
            📄 작업일지 조회
          </button>
        </div>
        <div>
          <button onClick={goToWorkLogPage} className="py-2 px-4 border border-black bg-white text-black font-bold rounded text-base">
            ✍🏻 작업일지 작성
          </button>
        </div>
        <div>
          <button onClick={goToDashboard} className="py-2 px-4 border border-black bg-white text-black font-bold rounded text-base mt-2 mr-40">
            📊 데이터 분석자료
          </button>
        </div>
      </div>
      <PersonWorkData />
    </div>
    <button onClick={toggleController} className="flex justify-center items-center w-12 h-12 rounded-full bg-blue-500 text-white shadow-lg absolute bottom-0 right-0">
      <img src={controllerImg} alt="Controller" className="w-full h-full"/>
    </button>
  </div>
);

};

export default ControllerButton;