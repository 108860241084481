// src/components/workplace/PlaceSearch.js
import React, { useState } from 'react';

const PlaceSearch = ({ onSearch }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSearchClick = () => {
    onSearch(searchTerm);
  };

  return (
    <div className="mb-4 flex justify-between items-center">
      <input
        type="text"
        placeholder="현장명을 검색하세요"
        value={searchTerm}
        onChange={handleSearchChange}
        className="w-full p-2 border border-gray-300 rounded-md"
      />
      <button 
        onClick={handleSearchClick} 
        className="ml-4 border-2 border-gray-200 bg-white hover:bg-gray-100 text-black font-bold py-2 px-4 rounded h-10"
      >
        🔍
      </button>
    </div>
  );
};

export default PlaceSearch;
