import React, { useState } from 'react';

const PlaceModal = ({ isOpen, onClose, onSave, initialData }) => {
  const [formData, setFormData] = useState({
    place: initialData.place,
    address: initialData.address,
    unit: initialData.unit,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(formData);
    onClose(); // 모달 닫기
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" id="my-modal">
      <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
        <div className="mt-3 text-center">
          <h3 className="text-lg leading-6 font-medium text-gray-900">현장 정보 수정</h3>
          <form onSubmit={handleSubmit} className="mt-2">
            <input
              name="place"
              value={formData.place}
              onChange={handleChange}
              className="mt-2 px-3 py-3 border border-gray-300 rounded-md w-full"
              placeholder="현장명"
            />
            <input
              name="address"
              value={formData.address}
              onChange={handleChange}
              className="mt-2 px-3 py-3 border border-gray-300 rounded-md w-full"
              placeholder="주소"
            />
            <input
              name="unit"
              value={formData.unit}
              onChange={handleChange}
              className="mt-2 px-3 py-3 border border-gray-300 rounded-md w-full"
              placeholder="호기"
            />
            <div className="mb-2">여러 개의 호기를 입력할 때는 쉼표(,)를 사용하세요.</div>
            <div className="mb-2">데이터를 동시에 입력 할 수 있습니다.</div>
            <div className="mb-2">(예) 101-1, 101-2, 102-1, 102-2</div>
            <div className="items-center px-4 py-3">
              <button
                id="ok-btn"
                className="px-4 py-2 bg-blue-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                저장
              </button>
            </div>
          </form>
          <div className="items-center px-4 py-3">
            <button
              onClick={onClose}
              className="px-4 py-2 bg-gray-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500"
            >
              취소
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlaceModal;
